.wrapper {
  padding: 50px 0;
  background: #edfaff;
}

.container {
  max-width: 1114px;
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 60px;
  position: relative;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.logo {
  max-width: 121px;
  margin-bottom: 25px;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 157.29%;
  color: #000000;
  max-width: 328px;
}
.heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;

  text-align: center;

  color: #000000;
}
.link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;

  color: #3d3d3d;
  text-decoration: none;
}
.contactUs {
  max-width: 241px;
}
.copyrightText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: #000000;
  text-align: center;
  background: #d7eff1;
  padding: 12px 15px;
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 0.8fr auto auto;
    gap: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr auto;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .box {
    gap: 15px;
  }
}

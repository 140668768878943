.wrapper {
  padding: 100px 0;
}

.container {
  max-width: 1109px;
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.image {
  max-width: 353px;
  width: 100%;
}
.textWrapper {
  max-width: 714px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  color: #012970;
}
.textContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding-top: 40px;
}
.textBox {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;
}
.arrow {
  font-size: 20px;
  margin-top: 3px;
  color: #012970;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 157.29%;
  color: #000000;
}
@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 575px) {
  .textContainer {
    grid-template-columns: 1fr;
  }
  .title {
    font-size: 40px;
  }
}

.wrapper {
  padding: 50px 0;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.pattern {
  position: absolute;
  top: 20px;
  right: 30%;
  z-index: -1;
}
.container {
  background: #ffffff;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 125%;

  color: #012970;
  padding-bottom: 15px;
  text-align: center;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 120%;
  color: #000000;
  text-align: center;
  padding-bottom: 45px;
}
.form {
  max-width: 680px;
  margin: 0 auto;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
}
.selectSubject {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;

  color: #000000;
}
.subjectContainer {
  display: flex;
  flex-direction: column;

  gap: 12px;
}
.subjectListContainer {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.subjectList {
  display: flex;
  align-items: center;
  gap: 5px;
}
.subject {
  line-height: 100%;
  font-weight: 400;
}
.circle {
  cursor: pointer;
  font-size: 20px;
  margin-top: 3px;
}
.inputWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 30px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #3d3d3d;
}
.input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  border: none;
  outline: none;
  border-bottom: 1px solid #3d3d3d;
  padding: 10px 0px;
}
.textArea {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  border: none;
  outline: none;
  border-bottom: 1px solid #3d3d3d;
  padding: 10px 0px;
  width: 100%;
  height: 100%;
}
.button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: #f6af03;
  border-radius: 5px;
  padding: 10px 18px;
  border: none;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .form {
    padding: 35px 30px;
  }
}
@media only screen and (max-width: 575px) {
  .wrapper {
    width: 100%;
  }
  .inputWrapper {
    grid-template-columns: 1fr;
  }
  .title {
    font-size: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .text {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .form {
    padding: 25px 20px;
  }
}

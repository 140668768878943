.wrapper {
  padding-bottom: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 968px;
  width: 90%;
  margin: 0 auto;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 130%;
  color: #012970;
}

.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 120%;
  color: #000000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 80px;
}
.features {
  display: grid;
  grid-template-columns: 0.9fr 1.1fr 0.9fr;
  align-items: flex-start;
  width: 100%;
  gap: 60px;
}
.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
}
.imageContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.image {
  max-width: 280px;

  height: 200px;
}
.info {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  /* or 41px */

  text-align: center;

  color: #000000;
}
@media only screen and (max-width: 1199px) {
  .features {
    gap: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .features {
    max-width: 700px;
    grid-template-columns: 1fr 1fr;
    gap: 50px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 50px;
  }
  .feature {
    gap: 15px;
  }
  .title {
    font-size: 45px;
  }
  .text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 40px;
  }
  .text {
    font-size: 20px;
  }
}

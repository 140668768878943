.heroSection {
  background-repeat: no-repeat;
  position: relative;
}

.heroSection:before {
  content: "";
  top: -20%;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #edfaff;
  border-radius: 0 0 50% 50%;
  transform: scale(1.6);
  z-index: -1;
}

.container {
  padding-top: 130px;
  max-width: 936px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.textWrapper {
  max-width: 586px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 130%;
  color: #012970;
}
.textContainer {
  padding: 15px 0;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 157.29%;
  text-align: justify;
  color: #000000;
  padding: 5px 0;
}
.store {
  display: flex;
  align-items: center;
  gap: 18px;
  padding-top: 8px;
}
.image {
  max-width: 253px;
}

@media only screen and (max-width: 1280px) {
  .heroSection:before {
    border-radius: 0 0 45% 45%;
    transform: scale(1.6);
  }
}
@media only screen and (max-width: 991px) {
  .container {
    flex-direction: column;
  }
  .heroSection:before {
    border-radius: 0 0 40% 40%;
    transform: scale(1.5);
    top: -22%;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding-top: 115px;
  }
  .heroSection:before {
    border-radius: 0 0 35% 35%;
  }
  .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 520px) {
  .heroSection:before {
    border-radius: 0 0 200px 200px;
    transform: scale(1.5);
    top: -20%;
  }
}
@media only screen and (max-width: 400px) {
  .title {
    font-size: 40px;
  }
}

.modal {
  position: absolute;
  width: 100%;
  max-width: 400px;
  width: 95%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 2;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #4bb543;
  border-radius: 12px;
  padding: 20px;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #fff;
  padding-bottom: 5px;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%;
  color: #fff;
}
.icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  color: #fff;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 18px;
  }
  .title {
    font-size: 22px;
  }
}

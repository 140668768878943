.wrapper {
  padding-top: 130px;
  padding-bottom: 50px;
}
.container {
  max-width: 991px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-align: center;

  color: #2b044d;
}
.date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #444444;
}
.title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 30.416px;
  line-height: 120%;

  display: flex;
  align-items: center;

  color: #2b044d;
  text-decoration: none;
}
.text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  text-align: justify;
  color: #444444;
  text-decoration: none;
  padding: 7.5px 0;
}
.list {
  margin: 0;
  padding-left: 20px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: 115px;
  }
}

@media only screen and (max-width: 575px) {
  .title {
    font-size: 28px;
  }
  .text {
    font-size: 15px;
  }
}

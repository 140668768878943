.wrapper {
  position: fixed;
  width: 100%;
  z-index: 1;

  padding: 15px 0;
}
.activeNavbar {
  background: #edfaff;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1125px;
  width: 90%;
  margin: 0 auto;
}
.logo {
  width: 121px;
}
.navItems {
  display: flex;
  align-items: center;
  gap: 25px;
}

.navItem {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.navActive {
  font-weight: 700;
}
.hamburger {
  color: #000000;
  font-size: 28px;
  cursor: pointer;
  display: none;
}
.close {
  color: #000000;
  font-size: 28px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;
  display: none;
}
@media only screen and (max-width: 767px) {
  .logo {
    width: 90px;
  }
  .navItems {
    position: fixed;
    top: 0;
    right: 0;
    width: 240px;
    height: 100vh;
    padding-top: 70px;
    padding-left: 30px;
    transform: translate(150%);
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s;
  }
  .sidebar {
    background: #fff;
    transform: translate(0%);
  }
  .hamburger {
    display: block;
  }
  .close {
    display: block;
  }
}
